import { MsalAuthProvider, LoginType } from "react-aad-msal";

const hostname = window && window.location && window.location.hostname;
const protocol = window.location.protocol;
const port = process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : "";
const uri = `${protocol}//${hostname}${port}`;

const azureAd = {
  instance: "https://login.microsoftonline.com",
  domain: "grundfos.onmicrosoft.com",
  clientId: "ff7033fb-af92-4c91-9a88-3c1f3276b824",
  tenantId: "dabd5d90-87c2-44c9-93cd-783e03236e40"
};

const config = {
  auth: {
    authority: `${azureAd.instance}/${azureAd.tenantId}`,
    clientId: azureAd.clientId,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage" as "sessionStorage",
    storeAuthStateInCookie: false
  }
};

const typeParameter = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: uri
};

export const authContext = new MsalAuthProvider(config, {}, typeParameter);
