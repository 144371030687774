import React from "react";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import styled from "styled-components";

const TitleContainer = styled.div`
  height: 50px;
`;

const AppCard = (props: any) => {
  return (
    <Card className="mb-2">
      <Card.Body>
        <TitleContainer>
          <Card.Title>{props.title}</Card.Title>
        </TitleContainer>
        <Card.Text>Owner: {props.owner}</Card.Text>
        <OverlayTrigger
          overlay={<Tooltip id={props.title}>{props.tooltip}</Tooltip>}
          placement={props.tooltipPlacement ? props.tooltipPlacement : "right"}
        >
          <a
            href={props.url ? props.url : "#"}
            className="btn btn-primary"
            target={props.url ? "_blank" : ""}
            rel="noopener noreferrer"
          >
            {props.buttonLabel}
          </a>
        </OverlayTrigger>
      </Card.Body>
    </Card>
  );
};

export default AppCard;
