import React from "react";
import Home from "./pages/Home";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { ConfigProvider } from "./provider/ConfigProvider";

import { AzureAD } from "react-aad-msal";
import { authContext } from "./clientApp/configAdal";

library.add(faUser);
library.add(faSignOutAlt);

function App() {
  return (
    <AzureAD provider={authContext} forceLogin>
      <ConfigProvider>
        <Home />
      </ConfigProvider>
    </AzureAD>
  );
}

export default App;
