import React from "react";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { authContext } from "../../../clientApp/configAdal";

const Header = (props: any) => {
  const context: any = authContext;
  return (
    <header id="header-container" className="header-container">
      <div id="global-navigation-container">
        <Navbar
          expand="lg"
          id="global-navigation"
          className="container navigation"
        >
          <Container>
            <ul
              id="global-navigation-left"
              className="float-left d-none d-sm-block"
            >
              <li>
                <a href="http://www.grundfos.com/" target="_blank" rel="noopener noreferrer">
                  www.grundfos.com
                </a>
              </li>
            </ul>
            <ul id="global-navigation-right" className="float-right">
              <li className="d-none d-sm-block">
                <FontAwesomeIcon icon="user" className="mr-1" />
                {context.account.name} |
              </li>
              <li className="d-none d-sm-block">
                <a href="#" onClick={() => authContext.logout()}>
                  <FontAwesomeIcon icon="sign-out-alt" className="mr-1" />
                  Sign out
                </a>
              </li>
            </ul>
          </Container>
        </Navbar>
      </div>
      <div id="blueheader-container">
        <Container id="blueheader">
          <div id="logo">
            <a href="/" title="Home">
              <img
                src="/img/grundfoslogo.png"
                title="Grundfos logo"
                alt="Grundfos logo"
              ></img>
            </a>
            <div className="site-name">
              Application Switcher
            </div>
          </div>
        </Container>
      </div>
      <div id="navbar-container">
        <Container id="navbar">
          <ul>
            <li className="active">
              <a href="/">Home</a>
            </li>
            <li className="float-right">1.0.0</li>
          </ul>
        </Container>
      </div>
    </header>
  );
};

export default Header;
