import React, { useState, useEffect, useContext } from "react";

import Layout from "../../layouts/DefaultLayout";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import AppCard from "./AppCard";

import styled from "styled-components";

import { ConfigContext } from "../../provider/ConfigProvider";

import isEmpty from "lodash/isEmpty";

import { IApp } from "./type";

const HomeContainer = styled.div`
  min-height: 425px;
`;

const Home = () => {
  const [appCards, setAppCards] = useState<IApp[]>([]);
  const { envConfig } = useContext(ConfigContext);
  const [config, initiateConfig] = envConfig;

  useEffect(() => {
    initiateConfig();
  }, []);

  useEffect(() => {
    if (!isEmpty(config)) {
      fetch(`/apps/${config.ENV}/apps.json`)
        .then(response => response.json())
        .then(data => setAppCards(data.apps));
    }
  }, [config]);

  return (
    <Layout>
      <Container>
        <HomeContainer>
          <Row className="mb-5 justify-content-center">
            {appCards ? (
              appCards.map((card: any) => (
                <Col xs={12} md={4} key={card.title}>
                  <AppCard
                    title={card.title}
                    owner={card.owner}
                    tooltip={card.tooltip}
                    tooltipPlacement={card.tooltipPlacement}
                    buttonLabel={card.buttonLabel}
                    url={card.url}
                  />
                </Col>
              ))
            ) : (
              <Spinner variant="primary" animation="border" />
            )}
          </Row>
        </HomeContainer>
      </Container>
    </Layout>
  );
};

export default Home;
