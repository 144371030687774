import React from 'react';

import Layout from './DefaultLayout';

const DefaultLayout = (props: any) => {
  return (
    <Layout {...props} />
  );
};

export default DefaultLayout;
