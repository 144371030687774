import React from "react";

import Header from "./Header";
import Footer from "./Footer";

const DefaultLayout = (props: any) => {
  return (
    <div>
      <Header />
      <div id="main-container" className="container">
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
