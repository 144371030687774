import React, { useState, createContext } from "react";
import isEmpty from "lodash/isEmpty";
import { DevConfig, TestConfig, QaConfig, ProdConfig } from "../../config";

export const ConfigContext = createContext<any>([{}, () => {}]);

export const ConfigProvider = ({
  children
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const [config, setConfig] = useState({});
  const initiateConfig = async () => {
    if (isEmpty(config)) {
      const hostname = window && window.location && window.location.hostname;
      switch (hostname) {
        case process.env.REACT_APP_K_D_HOSTNAME:
          return setConfig(DevConfig);
        case process.env.REACT_APP_K_T_HOSTNAME:
          return setConfig(TestConfig);
        case process.env.REACT_APP_K_Q_HOSTNAME:
          return setConfig(QaConfig);
        case process.env.REACT_APP_K_P_HOSTNAME:
          return setConfig(ProdConfig);
        default:
          return setConfig(DevConfig);
      }
    }
  };
  return (
    <ConfigContext.Provider
      value={{
        envConfig: [config, initiateConfig]
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
